// If you want to override variables do it here
// @import "variables";
$sidebar-bg-color: #f0f0f0 !default;
$sidebar-color: #555555 !default;

$highlight-color: #000000 !default;
$submenu-bg-color: #f0f0f0 !default;
$submenu-bg-color-collapsed: #f0f0f0 !default;
$icon-bg-color: #f0f0f0 !default;
$icon-size: 35px !default;
$submenu-indent: 14px !default;
$sidebar-collapsed-width: 80px !default;

@import 'react-pro-sidebar/dist/scss/styles.scss';


// $enable-ltr: true;
// $enable-rtl: true;

// Import CoreUI for React components library
// @import "@coreui/coreui/scss/coreui";

// // Import Chart.js custom tooltips styles
// @import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
// @import "example";

// If you want to add custom CSS you can put it here.
@import "custom";
@import 'Timeline.scss';
@import 'react-contextmenu.css';


/* html {
  font-family: 'Roboto', sans-serif;
  line-height: 1.4;
}

body {
  margin: 0;
} */

td,
th {
  border: 1px solid lightgray;
}

.db_table_cell {
  border: 0px solid lightgray;
}

.db_table_row {
  border: 0px solid lightgray;
}

/* 
.db_table_row :hover {
  background-color: #f5f5f5;
} */

.open_db_data_button {
  position: absolute;
  top: 50%;
  right: 9px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 3px 3px 3px 3px;
  cursor: pointer;
  background-color: white;
  color: gray;
  column-gap: 4px;
  font-size: 13px;
  font-weight: 500;
  width: 'fit-content';
  white-space: nowrap;
}

.open_db_data_button:hover {
  background-color: #f5f5f5;
}

.focusable_cell:focus {
  outline: 1px solid dodgerblue;
  background-color: azure;
}

.drag_db_data_handler {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 50%;
  right: 3px;
  transform: translateX(-60%);
  transform: translateY(-50%);
  border-radius: 4px;
  padding: 1;
  align-items: center;
  cursor: pointer;
}

.drag_db_data_handler:hover {
  background-color: #f1f1f1;
}

.add_db_data_handler {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateX(-60%);
  transform: translateY(-50%);
  border-radius: 4px;
  align-items: center;
  cursor: pointer;
}

.add_db_data_handler :hover {
  background-color: #f1f1f1;
}

/* .drag_db_data_handler_outside {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 50%;
  left: -24px;
  transform: translateX(-60%);
  transform: translateY(-50%);
  border-radius: 4px;
  padding: 1;
  align-items: center;
  cursor: pointer;
  z-index: 99;
}

.drag_db_data_handler_outside:hover {
  background-color: #f1f1f1;
}

.add_db_data_handler_outside {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 50%;
  left: -48px;
  transform: translateX(-60%);
  transform: translateY(-50%);
  border-radius: 4px;
  align-items: center;
  cursor: pointer;
  z-index: 99;
}

.add_db_data_handler_outside :hover{
  background-color: #f1f1f1;
} */

.db_table_handle_cell {
  border: 0px solid lightgray;
}

.db_table_header {
  color: #666;
  font-weight: 500;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#container {
  /* overflow-y: auto; */
  height: 100%;
  width: 100%;
  /* max-width: 800px; */
  background-color: white;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}

.MuiSvgIcon-root {
  font-size: 18px !important;
}

.tippy-box[data-theme~='tomato'] {
  background-color: tomato;
  color: yellow;
}

.slate-Draggable-dragHandle {
  background-color: white;
  border: 0px white;
  cursor: pointer;
}

/* .slate-Draggable {
  max-width: 800px;
  width: -webkit-fill-available;
} */
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: lightgray;
  opacity: 1;
  /* Firefox */
}

/* .slate-p,
.slate-h1,
.slate-h2,
.slate-h3,
.slate-h4,
.slate-h5,
.slate-h6,
.slate-hr,
.slate-blockquote,
.slate-code,
.slate-img,
.slate-pre,
.slate-table,
.slate-ul,
.slate-ol,
.slate-action_item,
.slate-code_block,
.slate-code_line,
.slate-rilmark,
.slate-subpage,
.slate-slides-embed,
.slate-dbviews-embed,
.slate-media_embed {
  max-width: 800px;
  width: -webkit-fill-available;
} */

/* .slate-selectable {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.group {
  max-width: 800px;
  width: -webkit-fill-available;
} */

.slate-SelectionArea {
  max-width: 896px;
  width: -webkit-fill-available;
}

.slate-tag {
  /* display: "inline-block"; */
  padding: 3px 3px 2px;
  border-radius: 4px;
  background-color: aliceblue;
  color: royalblue;
  font-size: 0.9em;
}

.slate-tag:focus {
  box-shadow: 0 0 0 2px #f90;
  outline: 2px solid red;
  background-color: aqua;
}

.slate-th {
  background-color: whitesmoke;
}

blockquote {
  display: block;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.fill-available {
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}

.highlighted-image {
  border: 1px solid #eee; /* 设置边框颜色 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 设置阴影，可以根据需要调整颜色和大小 */
  max-width: 100%;
  width: auto;
  height: auto;
}

.transparent-background {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0);
  /* 半透明黑色背景 */
  transition: background-color 0.3s ease;
  /* 添加过渡效果 */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.transparent-background:hover {
  background-color: rgba(0, 0, 0, 0.1);
  /* 鼠标悬停时变成更加半透明的背景色 */
}
.node {
    border-radius: 4px;
    border: 1px solid #fff;
    background-color: #fff;
    box-sizing: border-box;
}

.react-flow__resize-control.handle {
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background-color: transparent;
    border-color: transparent;
}

.react-flow__node {
    user-select: auto;
}

.circle-button {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: white;
    /* 默认背景色 */
    text-align: center;
    line-height: 50px;
    /* 垂直居中 */
    cursor: pointer;
    border: 1px solid gray;
    /* 边框 */
    transition: background-color 0.3s ease, color 0.3s ease;
    /* 添加过渡效果 */
    align-items: center;
    justify-content: center;
    display: flex;
}

.circle-button:hover {
    background-color: #f1f1f1;
    /* 悬停时的背景色 */
}

.circle-button-borderless {
    width: 28px;
    height: 28px;
    border-radius: 14px;
    /* background-color: white; */
    /* 默认背景色 */
    /* text-align: center; */
    /* line-height: 50px; */
    /* 垂直居中 */
    cursor: pointer;
    /* 边框 */
    transition: background-color 0.3s ease, color 0.3s ease;
    /* 添加过渡效果 */
    align-items: center;
    justify-content: center;
    display: flex;
}

.circle-button-borderless:hover {
    opacity: 0.5;
    /* 悬停时的背景色 */
}

.hoverStand {
    cursor: pointer;
    padding: 8px 6px;
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 14px;
    border-radius: 3px;
    user-select: none;
}

.hoverStand:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.hover_shadow:hover {
    opacity: 0.8;
    box-shadow: 0px 0px 8px #bbb;
}

.transparent-background {
    border-radius: 4px;
    /* background-color: rgba(0, 0, 0, 0); */
    /* 半透明黑色背景 */
    transition: background-color 0.3s ease;
    /* 添加过渡效果 */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.transparent-background:hover {
    /* background-color: rgba(0, 0, 0, 0.1); */
    opacity: 0.8;
    /* 鼠标悬停时变成更加半透明的背景色 */
}

.react-flow__node.highlight {
    background-color: #ff0072;
    color: white;
  }
$item-color: white;
$item-background: #2196f3;
$item-border: 1px solid #1a6fb3;
$item-selected-color: white;
$item-selected-background: #ffc107;
$item-selected-border: 1px solid #ff9800;

$row-background-even: transparent;
$row-background-odd: rgba(0, 0, 0, 0.05);

$border-color: #bbb;
$border-width: 1px;
$thick-border-width: 2px;
$sidebar-color: #ffffff;
$sidebar-background-color: #c52020;
$list-item-padding: 0 4px;
$weekend: rgba(250, 246, 225, 0.5);

.react-calendar-timeline {
  * {
    box-sizing: border-box;
  }

  .rct-outer {
    display: block;
    overflow: hidden;
    white-space: nowrap;
  }

  .rct-scroll {
    display: inline-block;
    white-space: normal; // was set to nowrap in .rct-outer
    vertical-align: top;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-touch-action: none;
    touch-action: none;
  }

  .rct-item {


    &:hover {
      z-index: 88;
    }

    .rct-item-content {
      position: sticky;
      position: -webkit-sticky;
      left: 0px;
      overflow: hidden;
      display: inline-block;
      border-radius: 2px;
      padding: 0 6px;
      height: 100%;
    }
  }

  .rct-sidebar {
    overflow: hidden;
    white-space: normal; // was set to nowrap in .rct-outer
    display: inline-block;
    vertical-align: top;
    position: fixed;
    box-sizing: border-box;
    border-right: $border-width solid $border-color;

    &.rct-sidebar-right {
      border-right: 0;
      border-left: $border-width solid $border-color;
    }

    .rct-sidebar-row {
      padding: $list-item-padding;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      box-sizing: border-box;
      margin: 0;
      border-bottom: $border-width solid $border-color;

      &.rct-sidebar-row-odd {
        background: $row-background-odd;
      }
      &.rct-sidebar-row-even {
        background: $row-background-even;
      }
    }
  }

  // TODO: rename or remove once we make breaking change to rename vertical lines
  // to columns
  .rct-vertical-lines {
    .rct-vl {
      position: absolute;
      border-left: 1px solid $border-color;
      z-index: 30;
      &.rct-vl-first {
        border-left-width: 2px;
      }
      &.rct-day-6,
      &.rct-day-0 {
        background: $weekend;
      }
    }
  }

  .rct-horizontal-lines {
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none;

    .rct-hl-even,
    .rct-hl-odd {
      border-bottom: $border-width solid $border-color;
      box-sizing: border-box;
      z-index: 40;
    }
    .rct-hl-odd {
      background: $row-background-odd;
    }
    .rct-hl-even {
      background: $row-background-even;
    }
  }

  .rct-cursor-line {
    position: absolute;
    width: 2px;
    background: $item-background;
    z-index: 51;
  }


  .rct-dateHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-bottom: 1px solid #bbb;
    cursor: pointer;
    font-size: 14px;
    background-color: rgb(240, 240, 240);
    border-left:  2px solid #bbb;
  }

  .rct-dateHeader-primary {
    background-color: initial;
    border-left:  1px solid #bbb;
    border-right: 1px solid #bbb;
    color: #fff
  }

  .rct-header-root {
    background: #c52020;
    border-bottom: 1px solid #bbb;
  }

  .rct-calendar-header {
    border: 1px solid #bbb,

  }

}

.simple-floatingedges {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.simple-floatingedges .react-flow__handle {
  width: 10px;
  height: 10px;
  background-color: #ccc;
}

.simple-floatingedges .react-flow__handle-top {
  top: -17px;
}

.simple-floatingedges .react-flow__handle-bottom {
  bottom: -17px;
}

.simple-floatingedges .react-flow__handle-left {
  left: -17px;
}

.simple-floatingedges .react-flow__handle-right {
  right: -17px;
}

.simple-floatingedges .react-flow__node-custom {
  background: #fff;
  border: 1px solid #1a192b;
  border-radius: 3px;
  color: #222;
  font-size: 12px;
  padding: 10px;
  text-align: center;
  width: 150px;
}

.react-flow__edge-selector {
  pointer-events: all;
}

.edgebutton {
  width: 20px;
  height: 20px;
  background: #ffffff;
  border: 1px solid #555;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.15);
  background-color: #f0f0f0;
}
.prompts-mine-container {
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.prompts-desc {
    font-size: 14px;
    color: #666;
}

.prompts-title {
    font-size: 18px;
    font-weight: bold;
    color: #888;
    margin-bottom: 10px;
    margin-top: 20px;
}

.add-prompt-button {
    width: fit-content;
    margin-bottom: 10px;
}

.prompts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 14px;
    margin-top: 10px;
}

@media (max-width: 768px) {
    .prompts-container {
        padding: 10px 5px;
    }

    .prompts-grid {
        grid-template-columns: 1fr;
    }

    .prompts-title {
        font-size: 16px;
    }

    .prompts-desc {
        font-size: 12px;
    }
}


.language-select-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.language-label {
    margin-right: 10px;
    font-size: 14px;
}

.language-select {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    max-width: 200px;
}

.load-more-button {
    color: dodgerblue;
    font-size: 18px;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.load-more-button:hover {
    background-color: rgba(30, 144, 255, 0.1);
}

@media (max-width: 768px) {
    .language-select-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .language-label {
        margin-bottom: 5px;
    }

    .language-select {
        width: 100%;
        max-width: none;
    }

    .load-more-button {
        font-size: 16px;
    }
}
// .wrapper {
//   width: 100%;
//   flex-grow: 1;
//   @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
//   will-change: auto;
//   @include transition(padding .15s);
// }

.sidebar-fixed {
  display: flex;
  justify-content: space-between;
  height: 100vh;
  z-index: 30;
  border-right: 1px solid rgba(85, 85, 85, 0.10)
}

.app-main {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  justify-content: center;
  overflow-x: hidden;
}

.main-list {
  width: 360px;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  // flex-direction: column;
  // height: 100vh;
  // justify-content: center;
}

.app-header {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  height: 2.3rem;
  background: var(--cui-header-bg, #fff);
  // border-bottom: var(--cui-header-border-width, 1px) solid var(--cui-header-border-color, #d8dbe0);
}

.header.header-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 29;
}

.header-breadcrumb {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding-left: 5px;
  padding-right: 5px;
  color: #333333;
  justify-content: space-between;
}

.app-content {
  overflow-y: auto;
  display: flex;
  position: relative;
  flex: 1;
  // justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.btn-toggle {
  cursor: pointer;
  width: 35px;
  height: 35px;
  background: #353535;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  display: none;
}

.btn-transparent {
  background-color: transparent;
  border: 0px transparent;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #555555;
}

.sidebar-footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 50px;

  .sidebar-btn {
    transition: width 0.3s;
    width: 80px;
    border-radius: 40px;
    background: rgba(255, 255, 255, 0.05);
    // color: #adadad;
    text-decoration: none;
    margin: 0 auto;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;

    span {
      font-size: 13px;
    }

    &:hover {
      color: $highlight-color;
    }
  }
}

.collapsed {
  .sidebar-btn {
    display: inline-block;
    line-height: 40px;
    padding: 0;
  }
}

.pro-sidebar.collapsed {
  width: 62px;
  min-width: 62px;
}

.spinner {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item>div>ul {
  padding-top: 0px;
  padding-bottom: 0px;
}

.pro-arrow-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  width: 28px;
  height: 24px;
  right: 0px;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 8px 20px 8px 21px;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  padding: 4px 20px 4px 15px;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  border-bottom: 1px solid rgba(85, 85, 85, 0.14)
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-footer {
  border-top: 1px solid rgba(85, 85, 85, 0.14)
}

.pro-sidebar .pro-menu.submenu-bullets .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
  margin-right: 8px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}

/* This is for menu item */
.pro-menu-item a.active {
  color: #333333;
  /* put any color you want */
  font-weight: 500;
}

/* This is for submenu item */
.nav-member .react-slidedown .pro-menu-item a.active {
  color: #000000;
  /* put any color you want */
  font-weight: 500;
}

.sortable-ghost {
  background-color: yellow;
}

.sortable-chosen {
  background-color: lightgray;
}

.trash_corner {
  display: flex;
  flex: 1;
}

.text-input {
  padding: 0px 10px 0px 10px;
}

.center-foreground {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  // width: 100px;
  // height: 100px;
}

.hoverStand {
  cursor: pointer;
  padding: 8px 6px;
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  border-radius: 3px;
  user-select: none;
}

.hoverStand:hover {
  background-color: #f1f1f1;
}

.hoverStand1 {
  cursor: pointer;
  margin: 0;
  display: flex;
  align-items: center;
  user-select: none;
}

.hoverStand1:hover {
  background-color: #e3e3e3;
  color: #333;
}

.hoverButton {
  cursor: pointer;
  padding: 8px 6px;
  margin: 0;
  display: flex;
  background-color: white;
  color: dodgerblue;
  align-items: center;
  font-size: 14px;
  border-radius: 3px;
  user-select: none;
}

.hoverButton:hover {
  background-color: dodgerblue;
  color: white;
}

.hoverButtonBlue {
  cursor: pointer;
  padding: 8px 6px;
  margin: 0;
  display: flex;
  background-color: dodgerblue;
  color: white;
  align-items: center;
  font-size: 14px;
  border-radius: 3px;
  user-select: none;
}

.hoverButtonBlue:hover {
  background-color: deepskyblue;
}

.round-corner-btn {
  padding: 1px 6px 1px 6px;
  display: flex;
  flex-direction: row;
  width: max-content;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: dodgerblue;
  background-color: #f8ffff;
  border: 1px solid dodgerblue;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;

  &:hover {
    background-color: aliceblue;
  }
}

.round-corner-input {
  padding: 3px;
  padding-left: 6px;
  padding-right: 4px;
  display: flex;
  flex-direction: row;
  width: max-content;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #333;
  background-color: #fff;
  border: 1px solid lightgray;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #f3f3f3;
  }
}

.loginMethodButton {
  cursor: pointer;
  margin: 0;
  display: flex;
  flex-direction: 'row';
  column-gap: 6px;
  background-color: white;
  color: #555;
  align-items: center;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ccc;
  height: 40px;
  user-select: none;
}

.loginMethodButton:hover {
  background-color: dodgerblue;
  color: white;
}

.hover_opacity:hover {
  opacity: 0.8;
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 280px);
  grid-row-gap: 14px;
  grid-column-gap: 14px;
}

.cm-editor {
  height: 100%;
}


.text-container {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 5;
  /* 显示5行文字 */
  line-height: 1.5;
  /* 设置行高，以确保文本易于阅读 */
  max-height: 7.5em;
  /* 5行文字的高度 */
}

.inner-wrapper {
  overflow-y: auto; /* 开启垂直滚动条 */
}

.inner-wrapper::-webkit-scrollbar {
  width: 8px; /* 设置滚动条宽度 */
}

.inner-wrapper::-webkit-scrollbar-thumb {
  background-color: #ccc; /* 设置滑块颜色 */
  border-radius: 4px; /* 设置滑块的圆角 */
}

.inner-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: #bbb; /* 设置滑块悬停时的颜色 */
}
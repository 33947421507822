.context-menu {
    background: white;
    border: 1px solid gray;
    box-shadow: 6px 6px 1px rgba(0, 0, 0, 10%);
    position: absolute;
    z-index: 10;
}

.context-menu button {
    border: none;
    display: block;
    padding: 0.5em;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
    text-align: left;
    width: 100%;
    background: white;
    cursor: pointer;
}

.context-menu button:hover {
    background: #eee;
}

.context-menu-h {
    background: white;
    border: 1px solid gray;
    border-radius: 4px;
    box-shadow: 6px 6px 1px rgba(0, 0, 0, 10%);
    padding: 1px;
    position: absolute;
    z-index: 10;
    display: flex;
    flex-direction: row;
}

.context-menu-h button {
    border: none;
    display: block;
    padding: 0.5em;
    text-align: left;
    background: white;
    cursor: pointer;
}

.context-menu-h button:hover {
    background: #eee;
}